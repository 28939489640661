<template>
  <!--
    Used to show software-component helper-commands.
  -->
  <div class="softwareComponentHelperCommands">
    <LoadingPlaceholder v-if="loading" />
    <template v-else-if="commands && commands.length > 0">
      <label>{{ 'Additional Links' }}</label>
      <ul
        class="mb-0"
      >
        <li
          v-for="(command, index) in commands"
          :key="`commandLink${ index }`"
        >
          <a
            :href="command.command"
            target="_blank"
          >{{ command.name }}</a>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  name: "SoftwareComponentHelperCommands",
  props: {
    typeId: {
      type: Number,
      required: true,
    },
    installationId: {
      type: String,
      required: true,
    },
    manualConfiguration: {
      type: String,
      required: false,
      default: null
    },
    componentUrl: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      response: null,
      commands: null,
      loading: false,
      selectedMessage: null,
      manualConfigurationJson: null
    }
  },
  watch: {
    typeId () {
      this.getHelperCommands();
    },
    manualConfiguration () {
      if(this.manualConfiguration) {
        try {
          this.manualConfigurationJson = JSON.parse(this.manualConfiguration);
        } catch {
          this.manualConfigurationJson = null;
        }
      }
    }
  },
  created () {
    if(this.manualConfiguration) {
      try {
        this.manualConfigurationJson = JSON.parse(this.manualConfiguration);
      } catch {
        this.manualConfigurationJson = null;
      }
    }
    this.getHelperCommands();
  },
  methods: {
    getHelperCommands () {
      this.loading = true;
      this.commands = null;
      this.axios.get(`/SoftwareComponent/GetSoftwareComponentCommands?typeId=${ this.typeId }`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.commands = response.data.filter(x => x.commandType == 'Helper');
        
        if (this.manualConfigurationJson && this.manualConfigurationJson.subType && this.manualConfigurationJson.subType.length > 0) {
          this.commands = this.commands.filter(x => !x.softwareComponentSubType || this.manualConfigurationJson.subType.includes(x.softwareComponentSubType))
        }
        else {
          this.commands = this.commands.filter(x => !x.softwareComponentSubType)
        }

        this.commands.forEach(e => {
          while(e.command.includes('{installation_id}')) {
            e.command = e.command.replace('{installation_id}', this.installationId);
          }
          
          if (this.componentUrl) {
            let domain = (new URL(this.componentUrl));
            domain = domain.hostname.replace('www.','');
            while(e.command.includes('{domain_name}')) {
              e.command = e.command.replace('{domain_name}', domain);
            }
          }

          if(this.manualConfigurationJson) {
            var comm = e.command;
            var count = (comm.match(/{manual_configuration:/g) || []).length;
            for(var i = 0; i < count; i++) {
                var indx = comm.indexOf('{manual_configuration:');
                var closingIndx = comm.indexOf('}');
                var property = comm.substring(indx, closingIndx).replace('{manual_configuration:', '');
                var value = this.manualConfigurationJson[property];
                comm = comm.replace('{manual_configuration:' + property + '}', value);
            }
            e.command = comm;
          }
        });
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>
.pre {
  height: 200px;
  overflow: scroll;
  white-space: pre;
}

</style>
