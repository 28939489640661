<template>
  <div class="softwareComponentDetail">
    <LoadingPlaceholder v-if="loading" />
    <Portlet
      :title="getTitle()"
      icon="hotel"
    >
      <template slot="buttons">
        <button
          class="btn btn-primary btn-sm mr-2"
          @click="createUrl()"
        >
          <font-awesome-icon
            icon="external-link-alt"
          />
        </button>
        <button
          v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel')"
          class="btn btn-primary btn-sm mr-2"
          @click="showSidebarEdit()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="edit"
          />
          <span>Edit</span>
        </button>
        <button
          v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel')"
          class="btn btn-secondary btn-sm"
          @click="openConfirmModal()"
        >
          <font-awesome-icon
            class="mr-2 gray"
            icon="trash"
          />
          <span>Remove</span>
        </button>
      </template>

      <ul class="nav nav-tabs mx-4">
        <li
          :class="['nav-item', { 'active': selectedTab == 'details' }]"
          @click="setTab('details')"
        >
          <a
            :class="['nav-link', { 'active': selectedTab == 'details' }]"
            href="#details"
            data-toggle="tab"
            aria-controls="details"
            aria-selected="true"
          >{{ $t('details') }}</a>
        </li>
        <li
          v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel') || authenticationHasRole('firstlevel')"
          :class="['nav-item', { 'active': selectedTab == 'deploymentInformation' }]"
          @click="setTab('deploymentInformation')"
        >
          <a
            :class="['nav-link', { 'active': selectedTab == 'deploymentInformation' }]"
            href="#deploymentInformation"
            data-toggle="tab"
            aria-controls="deploymentInformation"
            aria-selected="true"
          >Deployment Information</a>
        </li>
        <li
          v-if="softwareComponent.type.name == 'James Middleware' && (authenticationHasRole('admin') || authenticationHasRole('secondlevel') || authenticationHasRole('firstlevel'))"
          :class="['nav-item', { 'active': selectedTab == 'cronJobs' }]"
          @click="setTab('cronJobs')"
        >
          <a
            :class="['nav-link', { 'active': selectedTab == 'cronJobs' }]"
            href="#cronJobs"
            data-toggle="tab"
            aria-controls="cronJobs"
            aria-selected="true"
          >CronJobs</a>
        </li>
        <li
          v-if="softwareComponent.type.name == 'Peaksolution' && authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('firstlevel') || authenticationHasRole('secondlevel'))"
          :class="['nav-item', { 'active': selectedTab == 'products' }]"
          @click="setTab('products')"
        >
          <a
            :class="['nav-link', { 'active': selectedTab == 'products' }]"
            href="#products"
            data-toggle="tab"
            aria-controls="products"
            aria-selected="true"
          >Products</a>
        </li>
        <li
          v-if="(authenticationHasRole('scope_customer') && !authenticationHasRole('zerolevel')) 
            || (authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel') || authenticationHasRole('firstlevel')))"
          :class="['nav-item', { 'active': selectedTab == 'commands' }]"
          @click="setTab('commands')"
        >
          <a
            :class="['nav-link', { 'active': selectedTab == 'commands' }]"
            href="#commands"
            data-toggle="tab"
            aria-controls="commands"
            aria-selected="true"
          >{{ $t('commands') }}</a>
        </li>
        <li
          v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel'))"
          :class="['nav-item', { 'active': selectedTab == 'commandsLog' }]"
          @click="setTab('commandsLog')"
        >
          <a
            :class="['nav-link', { 'active': selectedTab == 'commandsLog' }]"
            href="#commandsLog"
            data-toggle="tab"
            aria-controls="url"
            aria-selected="true"
          >{{ $t('deviceDetailComponent.auditCommandsLog') }}</a>
        </li>
        <li
          v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel') || authenticationHasRole('firstlevel'))"
          :class="['nav-item', { 'active': selectedTab == 'loginData' }]"
          @click="setTab('loginData')"
        >
          <a
            :class="['nav-link', { 'active': selectedTab == 'loginData' }]"
            href="#loginData"
            data-toggle="tab"
            aria-controls="loginData"
            aria-selected="true"
          >{{ $t('softwareComponent.loginData') }}</a>
        </li>
        <li
          v-if="softwareComponent.type.name == 'Peaksolution' && authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel'))"
          :class="['nav-item', { 'active': selectedTab == 'configurationDiff' }]"
          @click="setTab('configurationDiff')"
        >
          <a
            :class="['nav-link', { 'active': selectedTab == 'configurationDiff' }]"
            href="#configurationDiff"
            data-toggle="tab"
            aria-controls="configurationDiff"
            aria-selected="true"
          >{{ $t('configuration') }} Diff</a>
        </li>
        <li
          v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel') || authenticationHasRole('firstlevel')"
          :class="['nav-item', { 'active': selectedTab == 'manualConfig' }]"
          @click="setTab('manualConfig')"
        >
          <a
            :class="['nav-link', { 'active': selectedTab == 'manualConfig' }]"
            href="#manualConfig"
            data-toggle="tab"
            aria-controls="config"
            aria-selected="true"
          >Manual {{ $t('configuration') }}</a>
        </li>
        <li
          v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel') || authenticationHasRole('firstlevel')"
          :class="['nav-item', { 'active': selectedTab == 'automatedConfig' }]"
          @click="setTab('automatedConfig')"
        >
          <a
            :class="['nav-link', { 'active': selectedTab == 'automatedConfig' }]"
            href="#automatedConfig"
            data-toggle="tab"
            aria-controls="config"
            aria-selected="true"
          >Automated {{ $t('configuration') }}</a>
        </li>
      </ul>

      <div class="tab-content px-4">
        <div
          id="details"
          :class="['tab-pane', { 'active': selectedTab == 'details' }]"
          role="tabpanel"
          aria-labelledby="details"
        >
          <template v-if="softwareComponent.type.responsible">
            <label>{{ $t('softwareComponent.responsible') }}</label>
            <div class="form-control mb-2">
              <span>
                {{ softwareComponent.type.responsible }}
              </span>
            </div>
            <div class="form-control">
              <span>
                {{ softwareComponent.type.link }}
              </span>
            </div>
            <hr class="m-0 mb-3">
          </template>
          <label>Zabbix monitored URL</label>
          <a
            :href="softwareComponent.url"
            class="form-control"
            target="_blank"
            style="color: #1f9fd9"
          >{{ softwareComponent.url }}</a>
          <hr class="m-0 mb-3">
          <template>
            <label>{{ $t('softwareComponent.licenceRequired') }}</label>
            <input
              :checked="softwareComponent.type.licenceRequired"
              type="checkbox"
              class="ml-2 pt-2"
              disabled
              readonly
            >
            <div class="m-0 mb-2" />
          </template>
          <template v-if="softwareComponent.type.licenceName">
            <label>{{ $t('softwareComponent.licenceName') }}</label>
            <div
              class="form-control"
              style="display:flex;"
            >
              <span>
                {{ softwareComponent.type.licenceName }}
              </span>
            </div>
            <hr class="m-0 mb-3">
          </template>
          <template v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel') || authenticationHasRole('firstlevel'))">
            <SoftwareComponentHelpers
              :installation-id="softwareComponent.installationId"
              :type-id="softwareComponent.type.id"
              :component-id="softwareComponent.id"
              :manual-configuration="softwareComponent.manualConfigurationJson"
              :component-url="softwareComponent.url"
            />
          </template>
          <div class="m-0 mb-3" />
          <template v-if="softwareComponent.versionIni">
            <label>{{ 'Version' }}</label>
            <textarea
              v-model="softwareComponent.versionIni"
              disabled
              class="form-control"
              rows="4"
            />
            <hr class="m-0 mb-3">
          </template>
          <template v-if="softwareComponent.cname">
            <label>{{ 'CNAME' }}</label>
            <div
              class="form-control"
              style="display:flex;"
            >
              <span>
                {{ softwareComponent.cname }} -
              </span>
              <span :style="`color: ${softwareComponent.cnameActive ? 'green' : 'red'};`">
                {{ softwareComponent.cnameActive ? 'Active' : 'Inactive' }}
              </span>
            </div>
            <hr class="m-0 mb-3">
          </template>
          <template v-if="softwareComponent.type.name.toLowerCase() == 'james ada web'">
            <label>{{ 'Google Maps API Key' }}</label>
            <input
              v-model="softwareComponent.mapsApiKey"
              disabled
              class="form-control"
              type="text"
            >
            <label>{{ 'Google Tag Manager' }}</label>
            <input
              v-model="softwareComponent.gtmId"
              disabled
              class="form-control"
              type="text"
            >
            <label>{{ 'Firebase API Key' }}</label>
            <input
              v-model="softwareComponent.firebaseApikey"
              disabled
              class="form-control"
              type="text"
            >
            <hr class="m-0 mb-3">
          </template>
          <label>{{ 'Monitoring Proxy' }}</label>
          <input
            ref="monitoringProxy"
            v-model="softwareComponent.monitoringProxy"
            disabled
            class="form-control"
            type="text"
          >
        </div>
        <div
          v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel') || authenticationHasRole('firstlevel')"
          id="deploymentInformation"
          :class="['tab-pane', { 'active': selectedTab == 'deploymentInformation' }]"
          role="tabpanel"
          aria-labelledby="deploymentInformation"
        >
          <SoftwareComponentVersionList
            :component-id="softwareComponent.id"
          />
        </div>
        <div
          v-if="softwareComponent.type.name == 'James Middleware' && (authenticationHasRole('admin') || authenticationHasRole('secondlevel') || authenticationHasRole('firstlevel'))"
          id="cronJobs"
          :class="['tab-pane', { 'active': selectedTab == 'cronJobs' }]"
          role="tabpanel"
          aria-labelledby="cronJobs"
        >
          <CronJobList
            :installation-id="softwareComponent.installationId"
          />
        </div>
        <div
          v-if="authenticationHasRole('admin') || authenticationHasRole('secondlevel') || authenticationHasRole('firstlevel')"
          id="manualConfig"
          :class="['tab-pane', { 'active': selectedTab == 'manualConfig' }]"
          role="tabpanel"
          aria-labelledby="manualConfigi"
        >
          <textarea
            ref="confTextArea" 
            v-model="manualConfJson" 
            class="form-control"
            type="text"
            rows="15"
            disabled
          />
        </div>
        <div
          v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel') || authenticationHasRole('firstlevel'))"
          id="automatedConfig"
          :class="['tab-pane', { 'active': selectedTab == 'automatedConfig' }]"
          role="tabpanel"
          aria-labelledby="automatedConfigi"
        >
          <p v-if="softwareComponent.type.automatedConfiguration">
            Automated Configuration Update is on.
          </p>
          <textarea
            ref="confTextArea" 
            v-model="automatedConfJson" 
            class="form-control"
            type="text"
            rows="15"
            disabled
          />
        </div>
        <div
          v-if="softwareComponent.type.name == 'Peaksolution' && authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel'))"
          id="configurationDiff"
          :class="['tab-pane', { 'active': selectedTab == 'configurationDiff' }]"
          role="tabpanel"
          aria-labelledby="configurationDiff"
        >
          <SoftwareComponentConfDiff
            :software-component="softwareComponent"
          />
        </div>
        <div
          v-if="softwareComponent.type.name == 'Peaksolution' && authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('firstlevel') || authenticationHasRole('secondlevel'))"
          id="products"
          :class="['tab-pane', { 'active': selectedTab == 'products' }]"
          role="tabpanel"
          aria-labelledby="products"
        >
          <PeakProductsList
            :installation-id="softwareComponent.installationId"
          />
        </div>
        <div
          v-if="(authenticationHasRole('scope_customer') && !authenticationHasRole('zerolevel')) ||
            (authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel') || authenticationHasRole('firstlevel')))"
          id="commands"
          :class="['tab-pane', { 'active': selectedTab == 'commands' }]"
          role="tabpanel"
          aria-labelledby="commands"
        >
          <SoftwareComponentCommandsExecute
            :installation-id="softwareComponent.installationId"
            :type-id="softwareComponent.type.id"
            :component-id="softwareComponent.id"
            :installation="installation"
            :manual-configuration="softwareComponent.manualConfigurationJson"
          />
        </div>
        <div
          v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel'))"
          id="commandsLog"
          :class="['tab-pane', { 'active': selectedTab == 'commandsLog' }]"
          role="tabpanel"
          aria-labelledby="commandsLog"
        >
          <SoftwareComponentCommandLog
            :component-id="softwareComponent.id"
          />
        </div>
        <div
          v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel') || authenticationHasRole('firstlevel'))"
          id="loginData"
          :class="['tab-pane', { 'active': selectedTab == 'loginData' }]"
          role="tabpanel"
          aria-labelledby="loginData"
        >
          <SoftwareComponentLoginDataList
            ref="loginList"
            :component-id="softwareComponent.id"
            :installation-id="softwareComponent.installationId"
            @reloadAuditLogs="reloadAuditLogs"
          />
          <DatabaseAuditLogList
            v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('admin') || authenticationHasRole('secondlevel'))"
            ref="databaseAuditLogList"
            :is-software-component-login="true"
            :software-component-parent-id="softwareComponent.id+''"
            @reload="reloadLoginList"
          />
        </div>
      </div>
    </Portlet>
    <Sidebar
      v-if="showSidebar"
      :show-sidebar="showSidebar"
      @close="close"
    >
      <SoftwareComponentEdit
        :software-component-obj="softwareComponent"
        @reload="reloadSoftwareComponents"
      />
    </Sidebar>
    <SweetModal
      ref="confirmDelete"
      :title="$t('introOutroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p v-if="softwareComponent">
        {{ $t('sureToDelete', {'0' : softwareComponent.name ? softwareComponent.name : softwareComponent.type.name}) }}
      </p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeSoftwareComponent()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
import SoftwareComponentCommandsExecute from '@/components/SoftwareComponent/SoftwareComponentCommandsExecute';
import SoftwareComponentEdit from '@/components/SoftwareComponent/SoftwareComponentEdit';
import SoftwareComponentCommandLog from '@/components/SoftwareComponent/SoftwareComponentCommandLog';
import SoftwareComponentHelpers from '@/components/SoftwareComponent/SoftwareComponentHelpers';
import SoftwareComponentLoginDataList from '@/components/SoftwareComponent/SoftwareComponentLoginDataList';
import SoftwareComponentVersionList from '@/components/SoftwareComponent/SoftwareComponentVersionList';
import SoftwareComponentConfDiff from '@/components/SoftwareComponent/SoftwareComponentConfDiff';
import PeakProductsList from '@/components/SoftwareComponent/PeakProductsList';
import CronJobList from '@/components/SoftwareComponent/CronJobList';
import Sidebar from '@/components/Base/Sidebar.vue';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: "SoftwareComponentDetail",
  components: {
    SoftwareComponentCommandsExecute,
    SoftwareComponentCommandLog,
    SoftwareComponentEdit,
    SoftwareComponentHelpers,
    SoftwareComponentLoginDataList,
    SoftwareComponentVersionList,
    CronJobList,
    SoftwareComponentConfDiff,
    Sidebar,
    PeakProductsList,
    SweetModal,
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  mixins: [
    authenticationMixin
  ],
  props: {
    softwareComponent: {
      type: Object,
      required: true
    },
    installation: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      showSidebar: false,
      manualConfJson: null,
      automatedConfJson: null,
      selectedTab: 'details',
    }
  },
  watch: {
    softwareComponent () {
      this.parseKubernetesProperties();
     if(this.softwareComponent.manualConfigurationJson) {
       try {
          var parseJSON = JSON.parse(this.softwareComponent.manualConfigurationJson);
          this.manualConfJson = JSON.stringify(parseJSON, undefined, 4);
       } catch  {
         this.manualConfJson = this.softwareComponent.manualConfigurationJson;
       }
      }
      else {
        this.manualConfJson = null;
      }
      
      if(this.softwareComponent.automaticConfigurationJson) {
       try {
          var aParseJSON = JSON.parse(this.softwareComponent.automaticConfigurationJson);
          this.automatedConfJson = JSON.stringify(aParseJSON, undefined, 4);
       } catch  {
         this.automatedConfJson = this.softwareComponent.automaticConfigurationJson;
       }
      }
      else {
        this.automatedConfJson = null;
      }
    }
  },
  created () {
    if(this.$route.query.tab) {
      this.selectedTab = this.$route.query.tab;
    }
    
    this.parseKubernetesProperties();
    if(this.softwareComponent.manualConfigurationJson) {
      try {
         var parseJSON = JSON.parse(this.softwareComponent.manualConfigurationJson);
        this.manualConfJson = JSON.stringify(parseJSON, undefined, 4);
       } catch {
         this.manualConfJson = this.softwareComponent.manualConfigurationJson;
       }
    }
    else {
      this.manualConfJson = null;
    }

    if(this.softwareComponent.automaticConfigurationJson) {
      try {
         var aParseJSON = JSON.parse(this.softwareComponent.automaticConfigurationJson);
        this.automatedConfJson = JSON.stringify(aParseJSON, undefined, 4);
       } catch {
         this.automatedConfJson = this.softwareComponent.automaticConfigurationJson;
       }
    }
    else {
      this.automatedConfJson = null;
    }
  },
  methods: {
    setTab (val) {
      this.selectedTab = val;
    },
    createUrl () {
      var url = `${window.location.origin}/installation/${this.softwareComponent.installationId}/software-components/${this.softwareComponent.id}?tab=${this.selectedTab}`;
      navigator.clipboard.writeText(url);
      this.$snotify.success("The URL has been copied to your clipboard.");
    },
    reloadAuditLogs () {
       if(this.$refs.databaseAuditLogList) {
         this.$refs.databaseAuditLogList.reloadAuditLogs();
       }
    },
    reloadLoginList () {
      this.$refs.loginList.getSoftwareComponentLoginData();
    },
    parseKubernetesProperties () {
      if(this.getTitle().toLowerCase() == "james ada web" && this.softwareComponent.automaticConfigurationJson) {
        var kubProp = JSON.parse(this.softwareComponent.automaticConfigurationJson);
        if(kubProp && kubProp.result && kubProp.result.body && kubProp.result.body.spec 
        && kubProp.result.body.spec.template && kubProp.result.body.spec.template.spec && kubProp.result.body.spec.template.spec.containers) {
          var first = kubProp.result.body.spec.template.spec.containers[0];
          if(first && first.env) {
            var gtm = first.env.find(x => x.name == "GTM_CONTAINER_ID");
            if(gtm) {
              this.softwareComponent.gtmId = gtm.value;
            }
            var maps = first.env.find(x => x.name == "GOOGLE_MAPS_API_KEY");
            if(maps) {
              this.softwareComponent.mapsApiKey = maps.value;
            }
            var fire = first.env.find(x => x.name == "FIREBASE_API_KEY");
            if(fire) {
              this.softwareComponent.firebaseApikey = fire.value;
            }
          }
        }
      }
      else {
        this.softwareComponent.gtmId = null;
        this.softwareComponent.firebaseApikey = null;
        this.softwareComponent.mapsApiKey = null;
      }
    },
    showSidebarEdit () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    reloadSoftwareComponents () {
      this.showSidebar = false;
      this.$emit('reload');
    },
    openConfirmModal () {
      this.$refs.confirmDelete.open();
    },
    removeSoftwareComponent () {
      this.axios.delete(`/SoftwareComponent/DeleteSoftwareComponent?id=${ this.softwareComponent.id }`)
      .then(() => {
        this.$snotify.success(this.$t('softwareComponent.deletedSuccessfully'));
        this.$emit('reload');
      })
      .finally(() => {
        this.deleteIndex = null;
      });
    },
    getTitle () {
      return this.softwareComponent.type.name;
    }
  }
}
</script>