<template>
  <!--
    Displays a backlog of used commands.
  -->
  <div class="softwareComponentCommandLog">
    <template>
      <Portlet
        :title="$t('history')"
        icon="history"
      >
        <template slot="buttons">
          <font-awesome-icon
            :class="['alt-pointer color-primary', { 'fa-spin' : loadingAudit}]"
            icon="sync-alt"
            style="vertical-align: middle;"
            @click="getAuditLogs()"
          />
        </template>
        <LoadingPlaceholder v-if="loadingAudit" />
        <div
          v-else-if="auditLogs"
          style="width: 100%;"
        >
          <table
            class="defaultTable"
          >
            <thead>
              <tr>
                <th>{{ $t('createDate') }}</th>
                <th>{{ $t('deviceDetailComponent.createdBy') }}</th>
                <th>{{ $t('type') }}</th>
                <th>{{ $t('deviceCommandDialogExecuteComponent.command') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(log, index) in auditLogs"
                :key="`row${ index }`"
              >
                <td>
                  {{ dateTime_dateTime(log.logDate) }}
                </td>
                <td>{{ log.username }}</td>
                <td>{{ log.newValue }}</td>
                <td>{{ log.oldValue }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p v-else>
          {{ $t('noDataAvailable') }}
        </p>
      </Portlet>
    </template>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: 'SoftwareComponentCommandLog',
  mixins: [
    dateTimeMixin
  ],
  props: {
    componentId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loadingAudit: true,
      auditLogs: null,
    }
  },
  watch: {
    componentId () {
      this.getAuditLogs();
    }
  },
  created () {
    this.getAuditLogs();
  },
  methods: {
    getAuditLogs () {
      this.loadingAudit = true;
      this.axios.get(`/DatabaseAuditLog/GetDatabaseAuditLogsByParentId?entity=SoftwareComponentCommandExec&system=Metis&parentId=${this.componentId}`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.auditLogs = response.data;
        })
        .finally(() => {
          this.loadingAudit = false;
        });
    }
  }
}
</script>

<style>
thead th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: -1px;
  background: #f5f5f5 !important;
}
pre {
  white-space: pre-line;
}
td {
  vertical-align: top;
}
</style>
