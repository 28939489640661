<template>
  <Portlet
    title="Products"
    icon="mountain"
    class="peaksolution"
  >
    <div class="peakProductsList">
      <div>
        <LoadingPlaceholder v-if="productsLoading" />
        <template v-else>
          <span>
            The following Product SKU Groups are enabled on this Peaksolution instance
          </span>
          <BasicNameList :data-arr="productsList" />
        </template>
      </div>
      <div class="mt-4">
        <LoadingPlaceholder v-if="touristLoading" />
        <template v-else>
          <span>
            The following Tourist Cards are enabled on this Peaksolution instance
          </span>
          <BasicNameList :data-arr="touristList" />
        </template>
      </div>
    </div>
  </Portlet>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
import BasicNameList from '@/components/SoftwareComponent/BasicNameList';

export default {
  name: "PeakProductsList",
  components: {
    BasicNameList
  },
  mixins: [
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      touristLoading: true,
      productsLoading: true,
      touristList: null,
      productsList: null
    }
  },
  created () {
    this.getProducts();
    this.getTouristCards();
  },
  methods: {
    getTouristCards () {
      this.touristLoading = true;
      var user = this.authenticationGetUser();
      let commandRequest = {
        'installationId': this.installationId,
        'token': user.details.access_token
      };
      this.axios.post(`/SoftwareComponent/GetTouristOrProductInfo?tourist=true`, commandRequest)
        .then((response) => {
          if (response.data) {
            try {
              var parseJSON = JSON.parse(response.data);
              if(parseJSON.successful) {
                this.touristList = parseJSON.message;
                this.touristList = this.touristList.sort((a,b) => a['name'].localeCompare(b['name']));
              }
            } catch {
              this.touristList = [];
            } 
          }
        })
        .finally(() => {
          this.touristLoading = false;
        });
    },
    getProducts () {
      this.productsLoading = true;
      var user = this.authenticationGetUser();
      let commandRequest = {
        'installationId': this.installationId,
        'token': user.details.access_token
      };
      this.axios.post(`/SoftwareComponent/GetTouristOrProductInfo?tourist=false`, commandRequest)
        .then((response) => {
          if (response.data) {
            try {
              var parseJSON = JSON.parse(response.data);
              if(parseJSON.successful) {
                this.productsList = [];
                for(var item of parseJSON.message) {
                  this.productsList.push({name: item["SUBSTRING_INDEX(sku, '.', 1)"]})
                }
                this.productsList = this.productsList.sort((a,b) => a.name - b.name)
              }
            } catch {
              this.productsList = [];
            } 
          }
        })
        .finally(() => {
          this.productsLoading = false;
        });
    }
  }
}
</script>
<style>
.peakProductsList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
}

</style>