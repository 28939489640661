<template>
  <Portlet
    :title="$t('softwareComponent.loginData')"
    icon="key"
    class="softwareComponentLoginData"
  >
    <template slot="buttons">   
      <button
        v-if="authenticationHasRole('scope_staff') && (authenticationHasRole('secondlevel') || authenticationHasRole('admin') || authenticationHasRole('firstlevel'))"
        class="btn btn-sm btn-primary btn-block"
        @click="showAddLoginData()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="plus"
        />
        <span>{{ $t('add') }}</span>
      </button>
    </template>
    <div class="softwareComponentLoginDataList">
      <LoadingPlaceholder v-if="loading" />
    
      <template v-else>
        <Grid
          :ref="kgm_ref"
          :style="{height: 'auto'}"
          :data-items="kgm_computedGridItems(softwareComponentLoginDatas)"
          :columns="kgm_responsiveColumns()"
          :filterable="true"
          :filter="kgm_filter"
          :pageable="kgm_pagable"
          :page-size="kgm_take"
          :skip="kgm_skip"
          :take="kgm_take"
          :total="kgm_allGridItems(softwareComponentLoginDatas)"
          :sortable="{
            allowUnsort: kgm_allowUnsort,
            mode: kgm_sortMode
          }"
          :sort="kgm_sort"
          selected-field="selected"
          @rowclick="kgm_gridOnRowClick"
          @filterchange="kgm_gridFilterChange"
          @pagechange="kgm_gridPageChange"
          @sortchange="kgm_gridSortChange"
        >
          <template
            slot="usernameTemplate"
            slot-scope="{props}"
          >
            <td :class="props.className">
              <PasswordViewer
                :id="props.dataItem.id"
                :password="props.dataItem.username"
                :hide="true"
              />
            </td>
          </template>
          <template
            slot="passwordTemplate"
            slot-scope="{props}"
          >
            <td :class="props.className">
              <PasswordViewer
                :id="props.dataItem.id"
                :password="props.dataItem.password"
                @sawPw="createViewAuditLow(props.dataItem)"
              />
            </td>
          </template>
          <template
            slot="urlTemplate"
            slot-scope="{props}"
          >
            <td :class="props.className">
              <template v-if="canJsonParse(props.dataItem.url)">
                <p
                  class="alt-primary-color"
                  style="cursor: pointer;"
                  @click="openMoreModal(props.dataItem.url)"
                >
                  {{ props.dataItem.url && props.dataItem.url.length > 110 ? props.dataItem.url.substring(0, 110) + '...' : props.dataItem.url }}
                </p>
              </template>
              <a 
                v-else-if="props.dataItem.url"
                :href="props.dataItem.url" 
                target="_blank"
                class="alt-primary-color"
              >
                {{ props.dataItem.url.length > 80 ? props.dataItem.url.substring(0, 80) + '...' : props.dataItem.url }}
              </a>
            </td>
          </template>
          <template
            slot="descriptionTemplate"
            slot-scope="{props}"
          >
            <td :class="props.className">
              {{ getDescription(props.dataItem.description) }}
            </td>
          </template>
          <div
            slot="typeFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="typeFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a Type"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('typeFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <div
            slot="descriptionFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="descriptionFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a Description"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('descriptionFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <div
            slot="urlFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="urlFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a URL"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('urlFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <div
            slot="usernameFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="usernameFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a Username"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('usernameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
          <template
            slot="optionsTemplate"
            slot-scope="{props}"
          >
            <td :class="props.className">
              <div class="btn-group-vertical">
                <div class="btn-group">
                  <button
                    class="btn btn-primary btn-sm"
                    @click="showSidebarEdit(props.dataItem)"
                  >
                    <font-awesome-icon
                      class="mr-2"
                      icon="edit"
                    />
                    <span>Edit</span>
                  </button>
                  <button
                    class="btn btn-secondary btn-sm"
                    @click="openConfirmModal(props.dataItem)"
                  >
                    <font-awesome-icon
                      class="mr-2 gray"
                      icon="trash"
                    />
                    <span class="pr-3">Remove</span>
                  </button>
                </div>
                <button
                  v-if="props.dataItem.hasLoginButton && props.dataItem.componentType == 'Peaksolution' && props.dataItem.type == 'Application'"
                  class="btn btn-success btn-sm"
                  @click="login(props.dataItem)"
                >
                  <span>Login</span>
                </button>
              </div>
            </td>
          </template>
        </Grid>
      
        <Sidebar
          v-if="showSidebar"
          :show-sidebar="showSidebar"
          @close="close"
        >
          <SoftwareComponentLoginAdd
            v-if="addingElement"
            :component-id="componentId"
            @reload="reloadSoftwareComponentLogin"
          />
          <SoftwareComponentLoginEdit
            v-else
            :software-component-login="softwareComponentLoginData"
            @reload="reloadSoftwareComponentLogin"
          />
        </Sidebar>
      </template>
      <SweetModal
        ref="moreModal"
        class="overflowHidden"
      >
        <p
          v-if="moreText"
          style="white-space: pre-line; text-align: initial;"
        >
          {{ JSON.stringify(JSON.parse(moreText), null, 2) }}
        </p>
        <div class="clearfix" />
      </SweetModal>
      <SweetModal
        ref="confirmDelete"
        :title="$t('introOutroListComp.deleteElement')"
        icon="warning"
        blocking
        class="overflowHidden"
      >
        <p v-if="softwareComponentLoginData">
          {{ $t('sureToDelete', {'0' : softwareComponentLoginData.type}) }}
        </p>

        <button
          slot="button"
          class="btn btn-secondary float-left mb-3"
          @click="$refs.confirmDelete.close()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="times"
          />{{ $t('cancel') }}
        </button>
        <button
          slot="button"
          class="btn btn-danger float-right mb-3"
          @click="removeSoftwareComponentLoginData()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="trash"
          />{{ $t('delete') }}
        </button>
        <div class="clearfix" />
      </SweetModal>
    </div>
  </Portlet>
</template>

<script>

import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import Sidebar from '@/components/Base/Sidebar.vue';
import SoftwareComponentLoginAdd from '@/components/SoftwareComponent/SoftwareComponentLoginAdd';
import SoftwareComponentLoginEdit from '@/components/SoftwareComponent/SoftwareComponentLoginEdit';
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "SoftwareComponentCommandList",
  components: {
    SweetModal,
    Sidebar,
    SoftwareComponentLoginAdd,
    SoftwareComponentLoginEdit,
    PasswordViewer: () => import('@/components/PasswordViewer.vue')
  },
  mixins: [
    kendoGridMixin,
    authenticationMixin
  ],
  props: {
    componentId: {
      type: Number,
      required: true
    },
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      softwareComponentLoginData: null,
      softwareComponentLoginDatas: null,
      showSidebar: false,
      addingElement: false,
      deleteIndex: null,
      moreText: null,
      kgm_columns: [
        {
          field: 'type',
          filterable: true,
          filter: 'text',
          title: this.$t('type'),
          filterCell: 'typeFilter',
        },
        {
          field: 'username',
          filterable: true,
          filter: 'text',
          title: this.$t('username'),
          filterCell: 'usernameFilter',
          cell: 'usernameTemplate'
        },
        {
          field: 'password',
          filterable: false,
          title: this.$t('password'),
          filterCell: 'passwordFilter',
          cell: 'passwordTemplate'
        },
        {
          field: 'url',
          filterable: false,
          title: 'Connection',
          filterCell: 'urlFilter',
          cell: 'urlTemplate'
        },
        {
          field: 'description',
          filterable: true,
          title: this.$t('description'),
          filterCell: 'descriptionFilter',
          cell: 'descriptionTemplate',
          hideOn: ['mdDown']
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          hidden: !(this.authenticationHasRole('scope_staff') && (this.authenticationHasRole('secondlevel') || this.authenticationHasRole('firstlevel') || this.authenticationHasRole('admin')))
        }
      ]
    }
  },
  watch: {
    componentId () {
      this.getSoftwareComponentLoginData();
      this.$emit("reloadAuditLogs");
    }
  },
  created () {
    this.getSoftwareComponentLoginData();
  },
  methods: {
    openMoreModal (text) {
      this.moreText = text;
      this.$refs.moreModal.open();
    },
    canJsonParse (data) {
      try {
        JSON.parse(data);
        return true;
      }
      catch {
        return false;
      }
    },
    login (data) {
      let routeData = this.$router.resolve({path: `/installation/${this.installationId}/peak-login/${data.username}`});
      window.open(routeData.href, '_blank');
      this.$emit("reloadAuditLogs");
    },
    getDescription (val) {
      if(val) {
        return val.length > 100 ? val.substring(0, 100) + '...' : val
      }
      return null;
    },
    //sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showAddLoginData () {
      this.addingElement = true;
      this.show();
    },
    showSidebarEdit (emitValues) {
      this.setCurrentSoftwareComponentLoginData(emitValues);
      this.addingElement = false;
      this.show();
    },
    setCurrentSoftwareComponentLoginData (loginData) {
      this.createViewAuditLow(loginData);
      this.softwareComponentLoginData = Object.assign({}, loginData); 
    },
    //end sidebar

    //api
    reloadSoftwareComponentLogin () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getSoftwareComponentLoginData();
    },
    openConfirmModal (data) {
      this.setCurrentSoftwareComponentLoginData(data);
      this.deleteIndex = data.id;
      this.$refs.confirmDelete.open();
    },
    removeSoftwareComponentLoginData () {
      this.axios.delete(`/SoftwareComponent/DeleteSoftwareComponentLogin?id=${ this.deleteIndex }`)
      .then(() => {
        this.$snotify.success(this.$t('softwareComponent.loginDataDeletedSuccessfully'));
      })
      .finally(() => {
        this.$emit("reloadAuditLogs");
        this.deleteIndex = null;
        this.$refs.confirmDelete.close();
        this.getSoftwareComponentLoginData();
      });
    },
    createViewAuditLow (val) {
      this.axios.post(`/SoftwareComponent/AddSoftwareComponentLoginViewAudit?installationId=${ this.installationId }`, val).then(() => {this.$emit("reloadAuditLogs");});
    },
    getSoftwareComponentLoginData () {
      this.loading = true;
      this.axios.get(`/SoftwareComponent/GetSoftwareComponentLogins?softwareComponentId=${ this.componentId }`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.softwareComponentLoginDatas = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    }     
  }
}
</script>
<style>
.softwareComponentLoginDataList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
}

</style>