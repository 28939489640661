var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Portlet',{staticClass:"softwareComponentVersion",attrs:{"title":"Deployment Information","icon":"code-branch"}},[_c('div',{staticClass:"softwareComponentVersionList"},[(_vm.loading)?_c('LoadingPlaceholder'):[_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.softwareComponentVersions),"columns":_vm.kgm_responsiveColumns(),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.softwareComponentVersions),"sortable":{
          allowUnsort: _vm.kgm_allowUnsort,
          mode: _vm.kgm_sortMode
        },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"deploymentFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"deploymentFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Deployment"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('deploymentFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"imageFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"imageFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a image"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('imageFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"versionFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"versionFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Version"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('versionFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"cpuRequestFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"cpuRequestFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a CPU val."},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('cpuRequestFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"memoryRequestFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"memoryRequestFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Memory"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('memoryRequestFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"cpuLimitFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"cpuLimitFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a CPU"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('cpuLimitFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"memoryLimitFilter",fn:function(ref){
        var props = ref.props;
        var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"memoryLimitFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Deployment"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('memoryLimitFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}}])})]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }