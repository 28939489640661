<template>
  <!--
    Used to execute software-component-commands.
  -->
  <div class="softwareComponentCommands">
    <div class="row">
      <div class="col-12 col-sm-8">
        <LoadingPlaceholder v-if="loading" />
        <div v-else>
          <Grid
            :ref="kgm_ref"
            :style="{height: 'auto'}"
            :data-items="kgm_computedGridItems(commands)"
            :columns="kgm_responsiveColumns()"
            :filterable="true"
            :filter="kgm_filter"
            :pageable="kgm_pagable"
            :page-size="kgm_take"
            :skip="kgm_skip"
            :take="kgm_take"
            :total="kgm_allGridItems(commands)"
            :sortable="{
              allowUnsort: kgm_allowUnsort,
              mode: kgm_sortMode
            }"
            :sort="kgm_sort"
            selected-field="selected"
            @rowclick="kgm_gridOnRowClick"
            @filterchange="kgm_gridFilterChange"
            @pagechange="kgm_gridPageChange"
            @sortchange="kgm_gridSortChange"
          >
            <template
              slot="paramTemplate"
              slot-scope="{props}"
            >
              <td v-if="includesParam(props.dataItem.command)">
                <input
                  v-model="props.dataItem.parameters"
                  style="width: 100%;"
                >
              </td>
              <td v-else />
            </template>
           
            <div
              slot="nameFilter"
              slot-scope="{props, methods}"
              class="input-group"
            >
              <input
                ref="nameFilterInput"
                :value="props.value"
                type="text"
                class="form-control mr-2 rounded-right"
                placeholder="please enter a Name"
                @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
              >
              <div
                v-if="props.value"
                class="input-group-append"
              >
                <button
                  class="btn btn-light border rounded"
                  @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
                >
                  <font-awesome-icon
                    class="gray"
                    icon="times"
                  />
                </button>
              </div>
            </div>
            <div
              slot="commandFilter"
              slot-scope="{props, methods}"
              class="input-group"
            >
              <input
                ref="commandFilterInput"
                :value="props.value"
                type="text"
                class="form-control mr-2 rounded-right"
                placeholder="please enter a Command"
                @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
              >
              <div
                v-if="props.value"
                class="input-group-append"
              >
                <button
                  class="btn btn-light border rounded"
                  @click="kgm_resetInput('commandFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
                >
                  <font-awesome-icon
                    class="gray"
                    icon="times"
                  />
                </button>
              </div>
            </div>
            <template
              slot="optionsTemplate"
              slot-scope="{props}"
            >
              <td :class="props.className">
                <div class="btn-group">
                  <button
                    v-if="props.dataItem.description"
                    class="btn btn-primary btn-sm"
                    @click="showDescriptionModal(props.dataItem.description)"
                  >
                    <span>Description</span>
                  </button>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="executeCommand(props.dataItem)"
                  >
                    <font-awesome-icon
                      class="mr-2"
                      icon="terminal"
                    />{{ $t('execute') }}
                  </button>
                </div>
              </td>
            </template>
          </Grid>
        </div>
        <p>* if more than one parameter is needed separate them with ";"  eg. param1;param2</p>
      </div>
      <div class="col-12 col-sm-4">
        <LoadingPlaceholder v-if="executing" />
        <template v-else-if="response">
          <template v-if="commandType == 'Automata'">
            <div class="pl-3 pr-3">
              <div
                class="mt-2 row"
              >
                <label class="column font-weight-bold">{{ $t('message') }}:</label>
                <span
                  style="overflow: auto; white-space: pre-line;"
                  class="columnt ml-2"
                  v-html="cutMessage(automataMessage(), 500)"
                />
                <button
                  v-if="automataMessage().length > 500"
                  class="btn btn-primary btn-sm mt-2 float-right"
                  @click="openModal(automataMessage())"
                >
                  Complete Message
                </button>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="pl-3 pr-3">
              <div class="row">
                <label class="column font-weight-bold">{{ $t('successful') }}:</label>
                <span class="columnt ml-2">{{ response.successful }}</span>
              </div>
              <div
                v-if="response.message"
                class="mt-2 row"
              >
                <label class="column font-weight-bold">{{ $t('message') }}:</label>
                <span
                  style="overflow: auto;"
                  class="columnt ml-2"
                >{{ cutMessage(response.message, 100) }}</span>
                <button
                  v-if="JSON.stringify(response.message).length > 100"
                  class="btn btn-primary btn-sm mt-2 float-right"
                  @click="openModal(response.message)"
                >
                  Complete Message
                </button>
              </div>
              <div
                v-if="response.stderr"
                class="mt-2 row"
              >
                <label class="column font-weight-bold">{{ $t('error') }}:</label>
                <span class="columnt ml-2">{{ cutMessage(response.stderr, 100) }}</span>
                <button
                  v-if="response.stderr.length > 100"
                  class="btn btn-primary btn-sm mt-2 float-right"
                  @click="openModal(response.stderr)"
                >
                  Complete Error Message
                </button>
              </div>
              <div
                v-if="response.cause"
                class="mt-2 row"
              >
                <label class="column font-weight-bold">{{ $t('error') }}:</label>
                <span class="columnt ml-2">{{ cutMessage(response.cause, 100) }}</span>
                <button
                  v-if="response.cause.length > 100"
                  class="btn btn-primary btn-sm mt-2 float-right"
                  @click="openModal(response.cause)"
                >
                  Complete Error Message
                </button>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          {{ $t('noDataAvailable') }}
        </template>
      </div>
    </div>
    <SweetModal
      ref="message"
      :title="$t('message')"
      blocking
      class="overflowHidden commandsMessageModal"
      @close="setToggle"
    >
      <div
        v-if="response"
        style="max-height: 550px; overflow: scroll;"
      >
        <template v-if="!toggle">
          <div
            v-if="commandType != 'Automata'"
            style="white-space: pre-wrap;"
          >
            {{ selectedMessage }}
          </div>
          <div
            v-else
            style="white-space: pre-wrap;"
            v-html="selectedMessage"
          />
        </template>
        <template v-else>
          <div v-html="selectedMessageAsTable" />
        </template>
      </div>
      <toggle-button
        slot="button"
        v-model="toggle"
        :labels="{ checked: 'normal', unchecked: 'table' }"
        :width="80"
        :height="30"
        :font-size="12"
        class="mt-2"
      />
      <button
        slot="button"
        class="btn btn-secondary float-right ml-3"
        @click="$refs.message.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
    <SweetModal
      ref="description"
      :title="$t('description')"
      blocking
      class="overflowHidden"
    >
      <div
        v-if="description"
        style="max-height: 550px; overflow: scroll;"
      >
        <div
          style="white-space: pre-line;"
        >
          {{ description }}
        </div>
      </div>
      <button
        slot="button"
        class="btn btn-secondary float-right mb-3"
        @click="$refs.description.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: "SoftwareComponentCommandsExecute",
  components: {
    SweetModal
  },
  mixins: [
    dateTimeMixin,
    authenticationMixin,
    kendoGridMixin
  ],
  props: {
    typeId: {
      type: Number,
      required: true,
    },
    installationId: {
      type: String,
      required: true,
    },
    componentId: {
      type: Number,
      required: true,
    },
    manualConfiguration: {
      type: String,
      required: false,
      default: null
    },
    installation: {
      type: Object,
      required: false,
      default: null
    },
  },
  data () {
    return {
      response: null,
      executing: false,
      commands: null,
      loading: false,
      selectedMessage: null,
      commandType: null,
      description: null,
      toggle: false,
      manualConfigurationJson: null,
      kgm_columns: [
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: this.$t('name'),
          filterCell: 'nameFilter'
        },
        {
          field: 'command',
          filterable: true,
          filter: 'text',
          title: 'Command',
          filterCell: 'commandFilter'
        },
        {
          filterable: false,
          title: 'Parameters*',
          cell: 'paramTemplate'
        },
        {
          filterable: false,
          title: this.$t('deviceCommandDialogExecuteComponent.options'),
          cell: 'optionsTemplate'
        }
      ]
    }
  },
  computed: {
    selectedMessageAsTable () {
      if(this.selectedMessage) {
        try {
          let str = "<table border==\"1\"><tr>"
          for (var key in this.selectedMessage[0]) {
            str += '<td>' + key + '</td>';
          }
          str += "</tr>"
          for (var i = 0; i < this.selectedMessage.length; i++) {
            str += "<tr>"
            for (key in this.selectedMessage[i]) {
              str += '<td>' + this.selectedMessage[i][key] + '</td>';
            }
            str += "</tr>"
          }
          str += "</table>";
          return str;
        }
        catch {
          return null
        }
      }
      return null;
    }
  },
  watch: {
    typeId () {
      this.response = null;
      this.getCommands();
    },
    manualConfiguration () {
      if(this.manualConfiguration) {
        try {
          this.manualConfigurationJson = JSON.parse(this.manualConfiguration);
        } catch {
          this.manualConfigurationJson = null;
        }
      }
    }
  },
  created () {
    if(this.manualConfiguration) {
      try {
        this.manualConfigurationJson = JSON.parse(this.manualConfiguration);
      } catch {
        this.manualConfigurationJson = null;
      }
    }
    this.getCommands();
  },
  methods: {
    setToggle () {
      this.toggle = false;
    },
    showDescriptionModal (val) {
      this.description = val
      this.$refs.description.open();
    },
    automataMessage () {
      if(this.response && typeof this.response == 'string') {
          return this.response;
      }

      let message = '';
      if(this.response.critical && this.response.critical.length > 0) {
        message += '<h4><b>Critical Issues:</b></h4>';
        this.response.critical.forEach(e => {
          message += `\u00A0\u00A0\u00A0\u00A0<b>Configuration Name:</b> ${e.kind}\n`;
          message += `\u00A0\u00A0\u00A0\u00A0<b>Configuration ID:</b> ${e.id}\n`;

          e.messages.forEach(m => {
            message += `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0<b>Issue:</b> ${m.message}\n`;

            m.paths.forEach(p => {
                message += `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0<b>Setting:</b> ${p.join(' » ')} \n`;
            });
          });

          message += '\n';
        });
      }

      if(this.response.warnings && this.response.warnings.length > 0) {
        message += '\n<h4><b>Warning Issues:</b></h4>';
        this.response.warnings.forEach(e => {
          message += `\u00A0\u00A0\u00A0\u00A0<b>Configuration Name:</b> ${e.kind}\n`;
          message += `\u00A0\u00A0\u00A0\u00A0<b>Configuration ID:</b> ${e.id}\n`;

          e.messages.forEach(m => {
            message += `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0<b>Issue:</b> ${m.message}\n`;

            m.paths.forEach(p => {
                message += `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0<b>Setting:</b> ${p.join(' » ')} \n`;
            });
          });
          message += '\n';
        });
      }
      return message;
    },
    cutMessage (val, nr) {
      if(val && typeof val != 'string') {
          val = JSON.stringify(val)
      }
      if(val && val.length > nr) {
        return val.substring(0, nr) + '...'
      }
      return val;
    },
    openModal (val) {
      this.selectedMessage = val
      this.$refs.message.open();
    },
    includesParam (val) {
      return val.includes('{parameter_');
    },
    getCommands () {
      this.loading = true;
      this.axios.get(`/SoftwareComponent/GetSoftwareComponentCommands?typeId=${ this.typeId }`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.commands = response.data.filter(x => x.commandType != 'Helper');
        if (this.manualConfigurationJson && this.manualConfigurationJson.subType && this.manualConfigurationJson.subType.length > 0) {
          this.commands = this.commands.filter(x => !x.softwareComponentSubType || this.manualConfigurationJson.subType.includes(x.softwareComponentSubType))
        }
        else {
          this.commands = this.commands.filter(x => !x.softwareComponentSubType)
        }

        if (this.installation && this.installation.environment == "Stage") {
          this.commands = this.commands.filter(x => x.name !== 'restartResellerApplication');
        }

      })
      .finally(() => {
        this.loading = false;
      });
    },
    executeCommand (command) {
      this.executing = true;
      var user = this.authenticationGetUser();
      this.response = null;
      let commandRequest = {
        'commandId': command.id,
        'command': command.command,
        'role': command.role,
        'commandType': command.commandType,
        'componentId': this.componentId,
        'installationId': this.installationId,
        'token': user.details.access_token,
        'parameters': command.parameters,
        'options': command.options
      };
      this.commandType = command.commandType;
      this.axios.post(`/SoftwareComponent/ExecuteNormalSoftwareComponentCommand`, commandRequest)
        .then((response) => {
          if (response.data) {
            try {
              var parseJSON = JSON.parse(response.data);
              this.response = parseJSON;
              if(parseJSON.result && command.commandType != 'Automata') {
                this.response = parseJSON.result;
              }
            } catch {
               this.response = response.data;
            } 
          }
        })
        .finally(() => {
          this.executing = false;
        });
    }
  }
}
</script>

<style scoped>
.pre {
  height: 200px;
  overflow: scroll;
  white-space: pre;
}
</style>

<style>
.commandsMessageModal .sweet-buttons  {
  padding-bottom: 12px;
}
</style>
