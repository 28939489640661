<template>
  <div class="cronJobList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(cronJobs)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(cronJobs)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="scheduleLink"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <a 
              :href="`https://crontab.guru/#${props.dataItem.schedule.replace(' ', '_')}`" 
              target="_blank"
              class="alt-primary-color"
            >
              {{ props.dataItem.schedule }}
            </a>
          </td>
        </template>
        
        
        <div
          slot="textFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="textFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('textFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>
    </template>
  </div>
</template>
<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: "CronJobList",
   mixins: [
    kendoGridMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      cronJobs: null,
      kgm_columns: [
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: 'Name',
          filterCell: 'textFilter'
        },
        {
          field: 'system',
          filterable: true,
          filter: 'text',
          title: 'System',
          filterCell: 'textFilter'
        },
        {
          field: 'image',
          filterable: true,
          filter: 'text',
          title: 'Image',
          filterCell: 'textFilter'
        },
        {
          field: 'version',
          filterable: true,
          filter: 'text',
          title: 'Version',
          filterCell: 'textFilter'
        },
        {
          field: 'schedule',
          filterable: true,
          filter: 'text',
          title: 'Schedule',
          filterCell: 'textFilter',
          cell: 'scheduleLink'
        }
      ]
    }
  },
  metaInfo () {
    return {
      title: 'CronJobs'
    }
  },
  created () {
    this.getCronJobs();
  },
  methods: {
    getCronJobs () {
      this.loading = true;
      this.axios.get(`/SoftwareComponent/GetInstallationCronJobs?installationId=${ this.installationId }`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.cronJobs = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    }     
  }
}
</script>