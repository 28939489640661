<template>
  <!--
    Used to show Peaksolution SoftwareComponent Configuration commit Difference
  -->
  <div class="softwareComponentConfDiff">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <div class="row m-auto mb-2">
        <button
          type="button"
          class="btn btn-sm btn-primary ml-auto mr-2"
          @click="openModal()"
        >
          Complete Configuration
        </button>
        <div
          class="btn-group"
          role="group"
          aria-label="Basic example"
        >
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            style="font-weight: bold;"
            :disabled="indexPosition == commits.length - 1"
            @click="changeIndexPosition(false)"
          >
            Previous
          </button>
          <button
            type="button"
            style="font-weight: bold;"
            class="btn btn-sm btn-secondary"
            :disabled="indexPosition == 0"
            @click="changeIndexPosition(true)"
          >
            Next
          </button>
          <button
            type="button"
            class="btn btn-sm btn-primary"
            style="font-weight: bold;"
            :disabled="indexPosition == 0"
            @click="resetIndexPosition()"
          >
            Last
          </button>
        </div>
      </div>
      <div class="row m-auto pt-3">
        <template v-if="indexPosition == commits.length - 1">
          <span class="m-auto">
            Initial Commit created on: {{ dateTime_dateTime(commits[indexPosition].committed_date) }}. See full Configuration.
          </span>
        </template>
        <template v-else>
          <span class="mr-auto">
            {{ dateTime_dateTime(commits[indexPosition + 1].committed_date) }}
          </span>
          <span class="ml-auto">
            {{ dateTime_dateTime(commits[indexPosition].committed_date) }}
          </span>
        </template>
      </div>
      <div
        v-if="diff"
        v-html="prettyHtml()"
      />
      <SweetModal
        ref="configuration"
        :title="`${$t('configuration')} - ${dateTime_dateTime(commits[indexPosition].committed_date)}`"
        blocking
        class="overflowHidden conf-modal"
      >
        <LoadingPlaceholder v-if="rawLoading" />
        <template v-else>
          <div
            v-if="rawFile"
            style="max-height: 550px; overflow: scroll;"
          >
            <div
              style="white-space: pre;"
            >
              {{ rawFile }}
            </div>
          </div>
        </template>
        <button
          slot="button"
          class="btn btn-secondary float-right mb-3"
          @click="$refs.configuration.close()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="times"
          />{{ $t('close') }}
        </button>
        <div class="clearfix" />
      </SweetModal>
    </template>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
import * as Diff2Html from 'diff2html';
import 'diff2html/bundles/css/diff2html.min.css';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: "SoftwareComponentConfDiff",
  components: {
    SweetModal
  },
  mixins: [
    authenticationMixin,
    dateTimeMixin
  ],
  props: {
    softwareComponent: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      rawLoading: false,
      commits: null,
      indexPosition: 0,
      diff: null,
      rawFile: null
    }
  },
  watch: {
    indexPosition () {
      this.getConfigurationDiffs();
    }
  },
  created () {
    this.getConfigurationCommits();
  },
  methods: {
    openModal () {
      this.getRawFile();
      this.$refs.configuration.open();
    },
    prettyHtml () {
      return Diff2Html.html(this.diff, {
        drawFileList: false,
        matching: 'lines',
        outputFormat: 'side-by-side',
      });
    },
    resetIndexPosition () {
      this.indexPosition = 0;
    },
    changeIndexPosition (val) {
      if(val) {
        if(this.indexPosition > 0) {
          this.indexPosition--;
        }
      }
      else {
        if(this.indexPosition < this.commits.length - 1) {
          this.indexPosition++;
        }
      }
    },
    getConfigurationCommits () {
      this.loading = true;
      this.commands = null;
      var user = this.authenticationGetUser();
      let postObject = {
        installationId: this.softwareComponent.installationId,
        token: user.details.access_token,
      }
      this.axios.post(`/Gitlab/GetFileCommits`, postObject)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.commits = response.data;
      })
      .finally(() => {
        this.loading = false;
        this.getConfigurationDiffs();
      });
    },
    getConfigurationDiffs () {
      this.loading = true;
      this.diff = null;
      var user = this.authenticationGetUser();
      let postObject = {
        installationId: this.softwareComponent.installationId,
        token: user.details.access_token,
        sha: this.commits[this.indexPosition].id
      }
      this.axios.post(`/Gitlab/GetFileDiffs`, postObject)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.diff = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    },
    getRawFile () {
      this.rawLoading = true;
      this.rawFile = null;
      var user = this.authenticationGetUser();
      let postObject = {
        installationId: this.softwareComponent.installationId,
        token: user.details.access_token,
        sha: this.commits[this.indexPosition].id
      }
      this.axios.post(`/Gitlab/GetRawFile`, postObject)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.rawFile = response.data;
      })
      .finally(() => {
        this.rawLoading = false;
      });
    }
  }
}
</script>

<style>
.conf-modal .sweet-modal .sweet-content .sweet-content-content{
  overflow: scroll;
}
</style>
