<template>
  <Portlet
    title="Deployment Information"
    icon="code-branch"
    class="softwareComponentVersion"
  >
    <div class="softwareComponentVersionList">
      <LoadingPlaceholder v-if="loading" />
    
      <template v-else>
        <Grid
          :ref="kgm_ref"
          :style="{height: 'auto'}"
          :data-items="kgm_computedGridItems(softwareComponentVersions)"
          :columns="kgm_responsiveColumns()"
          :filterable="true"
          :filter="kgm_filter"
          :pageable="kgm_pagable"
          :page-size="kgm_take"
          :skip="kgm_skip"
          :take="kgm_take"
          :total="kgm_allGridItems(softwareComponentVersions)"
          :sortable="{
            allowUnsort: kgm_allowUnsort,
            mode: kgm_sortMode
          }"
          :sort="kgm_sort"
          selected-field="selected"
          @rowclick="kgm_gridOnRowClick"
          @filterchange="kgm_gridFilterChange"
          @pagechange="kgm_gridPageChange"
          @sortchange="kgm_gridSortChange"
        >
          <div
            slot="deploymentFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="deploymentFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a Deployment"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('deploymentFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>

          <div
            slot="imageFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="imageFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a image"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('imageFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>

          <div
            slot="versionFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="versionFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a Version"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('versionFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>

          <div
            slot="cpuRequestFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="cpuRequestFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a CPU val."
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('cpuRequestFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>

          <div
            slot="memoryRequestFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="memoryRequestFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a Memory"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('memoryRequestFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>

          <div
            slot="cpuLimitFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="cpuLimitFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a CPU"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('cpuLimitFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>

          <div
            slot="memoryLimitFilter"
            slot-scope="{props, methods}"
            class="input-group"
          >
            <input
              ref="memoryLimitFilterInput"
              :value="props.value"
              type="text"
              class="form-control mr-2 rounded-right"
              placeholder="please enter a Deployment"
              @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
            >
            <div
              v-if="props.value"
              class="input-group-append"
            >
              <button
                class="btn btn-light border rounded"
                @click="kgm_resetInput('memoryLimitFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
              >
                <font-awesome-icon
                  class="gray"
                  icon="times"
                />
              </button>
            </div>
          </div>
        </Grid>
      </template>
    </div>
  </Portlet>
</template>

<script>

import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: "SoftwareComponentVersionList",
  mixins: [
    kendoGridMixin,
  ],
  props: {
    componentId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      softwareComponentVersions: null,
      kgm_columns: [
        {
          field: 'deployment',
          filterable: true,
          filter: 'text',
          title: 'Deployment',
          filterCell: 'deploymentFilter',
        },
        {
          field: 'image',
          filterable: true,
          filter: 'text',
          title: this.$t('image'),
          filterCell: 'imageFilter'
        },
        {
          field: 'version',
          filterable: true,
          title: 'Version',
          filterCell: 'versionFilter'
        },
        {
          field: 'cpuRequest',
          filterable: true,
          title: 'CPU (request)',
          filterCell: 'cpuRequestFilter'
        },
        {
          field: 'memoryRequset',
          filterable: true,
          title: 'Memory (request)',
          filterCell: 'memoryRequestFilter'
        },
        {
          field: 'cpuLimit',
          filterable: true,
          title: 'CPU (limit)',
          filterCell: 'cpuLimitFilter'
        },
        {
          field: 'memoryLimit',
          filterable: true,
          title: 'Memory (limit)',
          filterCell: 'memoryLimitFilter'
        },
      ]
    }
  },
  watch: {
    componentId () {
      this.getSoftwareComponentVersions();
    }
  },
  created () {
    this.getSoftwareComponentVersions();
  },
  methods: {
    //api
    getSoftwareComponentVersions () {
      this.loading = true;
      this.axios.get(`/SoftwareComponent/GetSoftwareComponentVersions?componentId=${ this.componentId }`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.softwareComponentVersions = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    }     
  }
}
</script>
<style>
.softwareComponentVersionList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
}

</style>