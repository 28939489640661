<template>
  <div class="basicNameList">
    <Grid
      :ref="kgm_ref"
      :style="{height: 'auto'}"
      :data-items="kgm_computedGridItems(dataArr)"
      :columns="kgm_responsiveColumns()"
      :filterable="true"
      :filter="kgm_filter"
      :pageable="kgm_pagable"
      :page-size="kgm_take"
      :skip="kgm_skip"
      :take="kgm_take"
      :total="kgm_allGridItems(dataArr)"
      :sortable="{
        allowUnsort: kgm_allowUnsort,
        mode: kgm_sortMode
      }"
      :sort="kgm_sort"
      selected-field="selected"
      @rowclick="kgm_gridOnRowClick"
      @filterchange="kgm_gridFilterChange"
      @pagechange="kgm_gridPageChange"
      @sortchange="kgm_gridSortChange"
    >
      <div
        slot="nameFilter"
        slot-scope="{props, methods}"
        class="input-group"
      >
        <input
          ref="nameFilterInput"
          :value="props.value"
          type="text"
          class="form-control mr-2 rounded-right"
          placeholder="please enter a Name"
          @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
        >
        <div
          v-if="props.value"
          class="input-group-append"
        >
          <button
            class="btn btn-light border rounded"
            @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
          >
            <font-awesome-icon
              class="gray"
              icon="times"
            />
          </button>
        </div>
      </div>
    </Grid>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: "BasicNameList",
  mixins: [
    kendoGridMixin
  ],
  props: {
    dataArr: {
      type: Array,
      required: true,
      default () {
        return [];
      }
    }
  },
  data () {
    return {
      kgm_columns: [
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: this.$t('name'),
          filterCell: 'nameFilter',
        }
      ]
    }
  }
}
</script>
<style>
.basicNameList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
}

</style>